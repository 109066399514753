.article-page {
  padding-top: 108px;
  display: block;
  margin: auto;
  max-width: 70%;

  h1.sectionTitle {
    display: block;
    font-size: 45px;
    font-weight: 400;
    line-height: .8;
    letter-spacing: 8px;
    margin: 80px 0 40px 0;
    color: #000;
    text-align: center;
  }

  h6{
    font-size: 24px;
    line-height: 34px;
    color: black;
    letter-spacing: .6px;
    font-weight: 400;
  }

  .description{
    font-size: 16px;
    line-height: 28px;
    color: #757575;
    letter-spacing: .6px;
    font-weight: 300;
  }
}
