
.account-page {
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color:rgba(255, 38, 74, 0.75);

  h1.sectionTitle {
    display: block;
    font-size: 45px;
    font-weight: 400;
    line-height: .8;
    letter-spacing: 8px;
    margin-bottom: 80px;
    color: #000;
  }

  .input-form {
    width: 100%;
    max-width: 550px;
    position: relative;
    height: 50px;
    background: rgba(204, 204, 204, 0.4);
    border-radius: 0;
    border: 1px solid #CCC;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
    font-weight: 300;
    letter-spacing: 3px;
    padding-left: 19px;
  }

  button.submit {
    width: fit-content;
    height: 50px;
    padding: 0 1.5rem;
    background: #ccc;
    border-radius: 4px;
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: none;
    transition: all ease 400ms;
    -moz-transition: all ease 400ms;
    -webkit-transition: all ease 400ms;
    -ms-transition: all ease 400ms;
    -o-transition: all ease 400ms;
  }

  button.submita:hover {
    background: #2c3da7;
    color: #fff;
  }

  .btn-recover {
    padding-left: 33px;
    line-height: 1.5;
    font-size: 14px;
    color: #000;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .btn-remember {
    color: #999999;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    position: relative;
    padding-left: 10px;
    letter-spacing: 2px;
    line-height: 3rem;
    margin: 0 0 15px 0;
  }

  .phrase {
    color: #999999;
    font-size: 10px;
    font-weight: 300;
    text-transform: uppercase;
    position: relative;
    padding-left: 10px;
    letter-spacing: 2px;
    line-height: 3rem;
    margin: 0 0 15px 0;
  }


  @media only screen and (min-width: 425px) and (max-width: 767.98px) {
    .input-form {
      max-width: 320px;
    }

    .group-actions {
      display: flex;
      flex-direction: column;
    }

    .btn-recover {
      padding-left: 0;
      padding-top: 33px;
    }
  }
}
